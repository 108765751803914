import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import "./blog.scss"

const Posts = ({ data }) => {
  const wpData = useStaticQuery(graphql`
    query {
      wpcontent {
        posts {
          nodes {
            id
            title
            postId
            content
            uri
            date
            slug
          }
        }
      }
      mobileImage: file(name: { eq: "iskustva-mo" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      desktopImage: file(name: { eq: "iskustva" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const sources = [
    wpData.mobileImage.childImageSharp.fluid,
    {
      ...wpData.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ]
  return (
    <>
      <SEO title="Iskustva" description="Lična iskustva sa Bahovim kapima" />
      <Layout>
        <article id="blog">
          <header>
            <h1>
              Lična iskustva <br></br> sa <br></br> Bahovim kapima
            </h1>
            <Img fluid={sources} />
          </header>
          {wpData.wpcontent.posts.nodes.map(post => {
            return (
              <section key={post.id}>
                <div>
                  <h3> {post.title} </h3>
                  <div
                    className="single-blog-content"
                    dangerouslySetInnerHTML={{
                      __html: post.content.slice(0, 300) + "...",
                    }}
                  />
                  <AniLink to={`/blog/${post.slug}`} fade duration={0.5}>
                    Pročitaj više
                  </AniLink>
                </div>
              </section>
            )
          })}
        </article>
      </Layout>
    </>
  )
}

export default Posts
